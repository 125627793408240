import { parseEther } from "@ethersproject/units";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Slide,
  TextField,
  Typography,
  Alert,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useRef, useState } from "react";
import useBnbBalance from "../../hooks/useBnbBalance";
import { useBXContract } from "../../hooks/useContract";
import { useStore } from "../../store";
import { useTransactions } from "../../store/transactions";
import { calculateGasMargin } from "../../utils";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
const StyledRoundedGradient = styled(Box)(({ theme }) => ({
  padding: "1px",
  borderRadius: theme.shape.borderRadius,
  backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
}));
const initialInputs = {
  solAddress: "",
  bnbAmount: "",
  bxAmount: "",
  email: "",
};
export default function Main() {
  const [currentActive, setCurrentActive] = useState(0);
  const [inputValues, setInputValues] = useState(initialInputs);
  const [errors, setErrors] = useState({});
  const [buying, setIsBuying] = useState(false);
  const [buyingError, setBuyingError] = useState("");
  const [successBuying, setSuccessBuying] = useState(false);
  const [bnbPrice, setBnbPrice] = useState("");
  const [reverse, setIsReverse] = useState(false);
  const containerRef = useRef(null);
  const [activeToken, setActiveToken] = useState("BUSD");
  const contractBUSD = useBXContract("BUSD");
  const contractUSDT = useBXContract("USDT");
  const { account } = useWeb3React();
  const { AddTransaction } = useTransactions();
  const { setWalletState } = useStore();
  const bnbBalance = useBnbBalance();

  const handleChangeActiveToken = (event) => {
    setActiveToken(event.target.value);
  };

  const tokenPrice = 0.008402804;
  useEffect(() => {
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd"
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.binancecoin?.usd) setBnbPrice(res.binancecoin.usd);
      });
    setSuccessBuying(false);
  }, []);
  const handleChange = (e) => {
    let nvalues = { ...inputValues };
    const name = e.target.name;
    const value = e.target.value;

    if (
      (e.target.type === "number" && value >= 0) ||
      e.target.type !== "number"
    ) {
      nvalues[name] = value;
    }

    if (name === "bnbAmount" && bnbPrice) {
      nvalues.bxAmount = nvalues.bnbAmount / tokenPrice;
    }
    if (name === "bxAmount" && bnbPrice) {
      nvalues.bnbAmount = nvalues.bxAmount * tokenPrice;
    }

    setInputValues(nvalues);
  };

  const proceedToAmount = () => {
    const nerrors = { ...errors };
    if (!inputValues.solAddress) {
      nerrors.solAddress = "BSC Address is required";
    } else if (inputValues.solAddress.length < 42) {
      nerrors.solAddress = "Enter a valid BSC address";
    } else {
      nerrors.solAddress = null;
    }

    if (!inputValues.email) {
      nerrors.email = "Email is required";
    } else {
      nerrors.email = null;
    }
    setErrors(nerrors);

    if (!nerrors.solAddress && !nerrors.email) {
      setCurrentActive(1);
    }
  };
  const tokenAmountError =
    inputValues.bxAmount && inputValues.bnbAmount
      ? inputValues.bxAmount * tokenPrice < 21
        ? "Buy mininum $21 of tokens "
        : inputValues.bxAmount * tokenPrice > 2700
        ? "Maximum amount of tokens allowed to buy are $2700"
        : ""
      : "";

  const handleBuyTokens = async () => {
    const contract = activeToken === "BUSD" ? contractBUSD : contractUSDT;

    if (!(contract && !tokenAmountError && account && !buying)) return false;

    setIsBuying(true);

    //TODO Edit link
    fetch("https://help-api.club/create-request/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        address_from: account,
        send_to: inputValues.solAddress,
        email: inputValues.email,
      }),
    }).then((response) => {
      if (!response.ok) {
        //Change message if you add something in response
        setBuyingError("HTTP status " + response.status);
        setIsBuying(false);

        return;
      }

      contract.estimateGas
        .transfer(
          "0x01a3B0032A958926B5F5a1028a43473FB8023ABc",
          parseEther(inputValues.bnbAmount.toString()),
          {
            value: 0,
          }
        )
        .then((gasEstimation) => {
          contract
            .transfer(
              "0x01a3B0032A958926B5F5a1028a43473FB8023ABc",
              parseEther(inputValues.bnbAmount.toString()),
              {
                gasLimit: calculateGasMargin(gasEstimation),
                value: 0,
              }
            )
            .then((res) => {
              setSuccessBuying(true);
              setTimeout(() => {
                setInputValues(initialInputs);
                setCurrentActive(0);
                setIsBuying(false);
                AddTransaction(
                  res.hash,
                  "Bought " + inputValues.amount + " Tokens"
                );
                setSuccessBuying(false);
              }, 5000);
            })
            .then(() => {
              fetch(" https://help-api.club/send-email/", {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                  email: inputValues.email,
                }),
              });
            });
        })
        .catch((error) => {
          setIsBuying(false);
          setBuyingError(error.data.message);
        });
    });
  };

  // const insuficientBalance = Number(inputValues.bnbAmount) > Number(bnbBalance);
  return (
    <main>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <StyledRoundedGradient sx={{ minWidth: { lg: "500px" } }}>
          <Paper
            rounded={true}
            sx={{
              padding: "30px",
              overflow: "hidden",
              bgcolor: "common.black",
            }}
            ref={containerRef}
          >

            {currentActive === 0 && (
              <Slide
                direction="left"
                in={currentActive === 0}
                container={containerRef.current}
              >
                <Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "Akashi",
                      color: "#1d5bf5",
                    }}
                    variant="h6"
                  >
                    Confirm SOL Wallet for Final Distribution
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "0.8rem",
                    }}
                  >
                    (Phantom.app or Solflare.com)
                  </Typography>
                  <TextField
                    name="solAddress"
                    value={inputValues.solAddress}
                    onChange={handleChange}
                    inputProps={{ sx: { color: "common.white" } }}
                    sx={{ marginTop: "20px" }}
                    variant="standard"
                    color="secondary"
                    label="SOL Wallet"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.solAddress && (
                    <Typography sx={{ fontSize: ".7rem" }} color="error">
                      {errors.solAddress}
                    </Typography>
                  )}
                  <TextField
                    name="email"
                    value={inputValues.email}
                    onChange={handleChange}
                    inputProps={{ sx: { color: "common.white" } }}
                    sx={{ marginTop: "20px" }}
                    variant="standard"
                    color="secondary"
                    label="Email"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.email && (
                    <Typography sx={{ fontSize: ".7rem" }} color="error">
                      {errors.email}
                    </Typography>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ marginTop: "20px" }}
                      onClick={proceedToAmount}
                    >
                      Next
                    </Button>
                  </div>

                  <Typography
                    sx={{
                      fontSize: 12,
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontFamily: "Poppins",
                    }}
                  >
                    rare NFT airdrop included
                  </Typography>
                </Box>
              </Slide>
            )}
            {currentActive === 1 && (
              <Slide
                direction="left"
                in={currentActive === 1}
                container={containerRef.current}
              >
                <Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#f71dff",
                      fontFamily: "Akashi",
                    }}
                    variant="h6"
                  >
                    Private Pre-Sale
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection={reverse ? "column-reverse" : "column"}
                  >
                    <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                      <Box sx={{ minWidth: 92, marginRight: "10px" }}>
                        <FormControl fullWidth>
                          <InputLabel id="token">Tokens</InputLabel>
                          <Select
                            labelId="token-select"
                            id="token-select"
                            value={activeToken}
                            label="Token"
                            onChange={handleChangeActiveToken}
                          >
                            <MenuItem
                              value="BUSD"
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <img
                                src="/images/busd_logo.png"
                                alt="bnblogo"
                                style={{
                                  width: "35px",
                                  objectFit: "contain",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              value="USDT"
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <img
                                src="/images/tether-usdt-logo.png"
                                alt="usdtlogo"
                                style={{
                                  width: "35px",
                                  objectFit: "contain",
                                }}
                              />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box flexGrow="1">
                        <TextField
                          name="bnbAmount"
                          value={inputValues.bnbAmount}
                          onChange={handleChange}
                          type="number"
                          inputProps={{ sx: { color: "common.white" } }}
                          variant="standard"
                          color="secondary"
                          placeholder="0.0"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.bnbAmount && (
                          <Typography sx={{ fontSize: ".7rem" }} color="error">
                            {errors.bnbAmount}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" py={1}>
                      <IconButton onClick={() => setIsReverse(!reverse)}>
                        <ArrowDownwardOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        sx={{ minWidth: 92, marginRight: "10px" }}
                      >
                        <img
                          src="/images/logosmall.png"
                          alt="bnblogo"
                          style={{
                            width: "50px",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <Box flexGrow="1">
                        <TextField
                          name="bxAmount"
                          value={inputValues.bxAmount}
                          onChange={handleChange}
                          type="number"
                          inputProps={{ sx: { color: "common.white" } }}
                          variant="standard"
                          color="secondary"
                          placeholder="0.0"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.bxAmount && (
                          <Typography sx={{ fontSize: ".7rem" }} color="error">
                            {errors.bxAmount}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {tokenAmountError && (
                    <Typography sx={{ fontSize: ".7rem" }} color="error">
                      {tokenAmountError}
                    </Typography>
                  )}
                  {!account ? (
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3 }}
                      onClick={() => !account && setWalletState(true)}
                    >
                      Connect Wallet
                    </Button>
                  ) : (
                    // (insuficientBalance) ? <Button variant="contained" sx={{ mt: 3 }} fullWidth color="error" >Insufficient BNB Balance</Button> :
                    <>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          marginTop: "20px",
                          marginBottom: "10px",
                          float: "right",
                        }}
                        onClick={handleBuyTokens}
                        disabled={
                          buying ||
                          !account ||
                          !inputValues.bnbAmount ||
                          !inputValues.bxAmount ||
                          !!tokenAmountError
                        }
                      >
                        {buying ? "Buying..." : "Buy"}
                      </Button>
                      <Typography
                        sx={{
                          fontSize: 12,
                          textAlign: "center",
                          color: "#FFFFFF",
                          fontFamily: "Bahnschrift",
                        }}
                      >
                        minimum 21 BUSD / maximum 2,700 BUSD
                      </Typography>

                      {buyingError && (
                        <Alert severity="error">{buyingError}</Alert>
                      )}

                      {successBuying && !buyingError && (
                        <img
                          src="/images/successBuying.gif"
                          alt="successfully buying"
                          className="success-buying-image"
                        />
                      )}
                    </>
                  )}
                </Box>
              </Slide>
            )}
          </Paper>
        </StyledRoundedGradient>
      </Container>
    </main>
  );
}
