import { formatEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';

export default function useBnbBalance() {
  const [balance, setBalance] = useState('');
  const { library, account } = useWeb3React();

  useEffect(() => {
    if (library)
      library.getBalance(account).then((res) => {
        setBalance(Number(formatEther(res)).toFixed(4));
      });
  }, [library, account]);
  return balance;
}
