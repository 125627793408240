import React, { createContext, useContext, useState } from "react";

const context = createContext();
export default function StoreProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  function setWalletState(value) {
    setIsOpen(value);
  }

  const value = { isOpen, setWalletState };
  return <context.Provider value={value}>{children}</context.Provider>;
}
export function useStore() {
  return useContext(context);
}
