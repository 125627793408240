import { Box } from "@mui/material";
import Header from "./components/Header";
import Main from "./components/Main";
import WalletModal from "./components/WalletModal";
import "./App.css";
function App() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: "url(/images/background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{ height: "100vh", bgcolor: "rgba(0, 0, 0, 0.787)", zIndex: "2" }}
      >
        <Header />
        <Main />
        <WalletModal />
      </Box>
      <div className="bx-iso-block">
        <img src="/images/BX_iso.png" alt="BX iso" className="bx-iso-image" />
      </div>
    </Box>
  );
}

export default App;
