import { AppBar, Link, Toolbar } from '@mui/material';
import { Box, styled } from '@mui/system';
import { WalletConnectButton } from '../WalletModal';
const StyledLink = styled(Link)(({ theme }) => ({
  margin: '0px 10px',
  padding: '10px',
  textDecoration: 'none',
  color: '#fff',
  fontSize: '15px',
  transition: 'all 0.4s',
  fontWeight: 'bold',
  cursor: 'pointer',
  textTransform: 'uppercase',
  '&:hover , &.Mui-focusVisible': {
    borderBottom: `3px solid rgb(76, 163, 253)`,
    color: 'rgb(76, 163, 253)',
  },
}));
const StyledDropDown = styled('span')(({ theme }) => ({
  textDecoration: 'none',
  color: '#fff',
  fontSize: '15px',
  transition: 'all 0.4s',
  fontWeight: 'bold',
  cursor: 'pointer',
  position: 'relative',
  textTransform: 'uppercase',
  padding: '0px 10px',
  display: 'flex',
  alignItems: 'center',
  '&>div': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    border: '1px solid rgb(76, 163, 253)',
    flexDirection: 'column',
    transform: 'translateY(100%)',
    backgroundColor: '#000',
    padding: '5px',
    display: 'none',
    '&>a': {
      padding: '5px 0',
    },
  },
  '&:hover > div': {
    display: 'flex',
  },
}));
export default function Header() {
  return (
    <AppBar position="sticky" sx={{ shadow: 'none', bgcolor: 'common.black' }}>
      <Toolbar>
        <img
          src="/images/logo-white.png"
          alt="logo"
          style={{ width: '150px', height: '40px' }}
        />
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <StyledLink href="https://bancambios.exchange/">Home</StyledLink>
          <StyledLink href="https://bancambios.exchange/swap">
            Stableswap
          </StyledLink>
          <StyledLink href="https://bancambios.exchange/alphatrade">
            Alphatrade
          </StyledLink>
          <StyledDropDown>
            Catapult <i className="arrow down" style={{ marginLeft: '10px' }}></i>
            <div>
              <StyledLink href="https://bancambios.exchange/catapult-eco-sto">
                Eto-STO's
              </StyledLink>
              <StyledLink href="https://bancambios.exchange/catapult-dlt-projects">
                DLT <br /> Projects
              </StyledLink>
            </div>
          </StyledDropDown>
          <StyledLink href="https://bancambios.exchange/nft">Nft</StyledLink>
          <StyledLink href="/" className="Mui-focusVisible">
            Token sale
          </StyledLink>
          <WalletConnectButton size="medium" />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
