import { Avatar, Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { SUPPORTED_WALLETS } from "../../connectors";
import usePrevious from "../../hooks/usePrevious";
import React, { useEffect } from "react";
import { useStore } from "../../store";
import useBnbBalance from "../../hooks/useBnbBalance";

export default function WalletModal() {
  const { isOpen, setWalletState } = useStore();
  const { activate, active, connector, error, account } = useWeb3React();
  const tryActivation = async (connector) => {
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector); // a little janky...can't use setError because the connector isn't set
        }
      });
  };
  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      isOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious))
    ) {
      setWalletState(false);
    }
  }, [
    setWalletState,
    active,
    connector,
    isOpen,
    activePrevious,
    connectorPrevious,
  ]);

  useEffect(() => {
    if (isWrongChainError(error) && window.ethereum) {
      window.ethereum?.send("wallet_addEthereumChain", [
        {
          chainId: "0x38",
          chainName: "Binance Smart Chain",
          nativeCurrency: {
            name: "Binance Coin",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://bsc-dataseed.binance.org"],
          blockExplorerUrls: ["https://bscscan.com"],
        },
        account,
      ]);
      // window.ethereum?.send('wallet_addEthereumChain', [{
      //     chainId: '0x61',
      //     chainName: 'Binance Smart Chain',
      //     nativeCurrency: {
      //         name: 'Binance Coin',
      //         symbol: 'BNB',
      //         decimals: 18,
      //     },
      //     rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
      //     blockExplorerUrls: ['https://testnet.bscscan.com'],
      // }, account])
    }
  }, [error, account]);
  return (
    <Dialog fullWidth open={isOpen} onClose={() => setWalletState(false)}>
      <Box>
        <Grid container>
          {Object.values(SUPPORTED_WALLETS).map((wallet) => {
            if (wallet.isInstalled()) {
              return (
                <Grid
                  item
                  md={6}
                  xs={12}
                  key={wallet.name}
                  sx={{ cursor: "pointer", padding: "10px" }}
                  onClick={() => tryActivation(wallet.connector)}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="20px"
                  >
                    <Avatar sx={{ bgcolor: "transparent" }} sizes="md">
                      <img
                        src={"/images/" + wallet.iconName}
                        alt="walleticon"
                        width="100%"
                      />
                    </Avatar>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {wallet.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      secondary
                      sx={{ textAlign: "center" }}
                    >
                      {wallet.description}
                    </Typography>
                  </Box>
                </Grid>
              );
            } else return null;
          })}
        </Grid>
      </Box>
    </Dialog>
  );
}
function isWrongChainError(error) {
  return error instanceof UnsupportedChainIdError;
}

export function WalletConnectButton({ size = "large" }) {
  const { setWalletState } = useStore();
  const { account, error, deactivate } = useWeb3React();
  const balance = useBnbBalance();
  return (
    <>
      {balance && account && (
        <Box display="flex" alignItems="center">
          <Typography color={"common.white"} sx={{ marginRight: "10px" }}>
            {balance} BNB
          </Typography>
        </Box>
      )}
      <Button
        variant="contained"
        color={isWrongChainError(error) ? "error" : "primary"}
        size={size}
        onClick={
          !account
            ? () => setWalletState(true)
            : () => {
                setWalletState(false);
                deactivate();
                console.log("");
              }
        }
      >
        {isWrongChainError(error) ? (
          <>Wrong Network</>
        ) : account ? (
          <>
            {account.slice(0, 4)}...{account.slice(account.length - 4)}
          </>
        ) : (
          <>Connect Wallet</>
        )}
      </Button>
    </>
  );
}
