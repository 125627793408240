import { BigNumber } from '@ethersproject/bignumber';

// add 10%
export function calculateGasMargin(value) {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

export function getScanLink(data, type) {
  const prefix =
    process.env.REACT_APP_chainId === 56
      ? 'https://bscscan.com'
      : 'https://ropsten.etherscan.io';
  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'block': {
      return `${prefix}/block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}
